import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="#"> Home </a>
                <span className="icon">/</span>
                <a href="#"> News </a>
                <span className="icon">/</span>
                <a href="#">Experience Centres: The Future of Retail Spaces</a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s">
                  In today's rapidly evolving retail landscape, traditional store setups—featuring product racks, display counters, and  <span className="color-orange1">cash registers—are shifting</span> towards immersive experience centres. Within the next 3 to 6 years, these spaces will transform profoundly, bringing retail far beyond the conventional setup we know today.
                </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <span className="lg-letter"> O </span>ver the past decade, we witnessed significant lifestyle changes driven by what experts call the IT revolution. But beneath the surface, we were already experiencing a taste of basic AI (Artificial Intelligence), influencing our shopping habits and expectations without us even realizing it. Now, AI is poised to reshape the retail industry at an unprecedented speed.

              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >

              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                The next 3 to 7 years are crucial for businesses aiming to thrive in this "AI era." Brands that fail to adapt may find themselves outpaced, replaced, or even forgotten. Leading brands, however, are investing heavily in research and development, preparing to stay competitive by designing EXPERIENCE CENTERS that meet the evolving demands of the modern consumer.
                <a href="#" className="text-info text-decoration-underline">

                </a>
                These experience centres aren’t just about shopping; At, DB ARCHITEKT., we are creating an immersive journey where customers can interact with products in new, engaging ways. It's a trend that fresh, innovative brands are also keen to adopt, preparing to compete with industry leaders and deliver unique retail experiences that could set them apart and due to these changes, client is witnessing the sales growth. These creating the base for trend setting ….
              </div>
            </div>
          </div>
        </div>
        {/* <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                 
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                 
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >

            </h6>
            <div
              className="text fsz-14 color-666 mb-40 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              The next 3 to 7 years are crucial for businesses aiming to thrive in this "AI era." Brands that fail to adapt may find themselves outpaced, replaced, or even forgotten. Leading brands, however, are investing heavily in research and development, preparing to stay competitive by designing EXPERIENCE CENTERS that meet the evolving demands of the modern consumer.
            </div>
            {/* <ul
              className="list-styled color-666 fsz-14 lh-5 mb-40 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <li>
                Alternatively, brighten up the space with strategically placed
                lighting fixtures
              </li>
              <li>
                A well-placed mirror can create the illusion of a larger
                bathroom
              </li>
              <li>
                Consider using a hanging organizer on the back of your bathroom
                door to store toiletries or cleaning supplies.
              </li>
            </ul> */}
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            </h6>
            <div
              className="text fsz-14 color-666 mb-90 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              These experience centres aren’t just about shopping; At, DB ARCHITEKT., we are creating an immersive journey where customers can interact with products in new, engaging ways. It's a trend that fresh, innovative brands are also keen to adopt, preparing to compete with industry leaders and deliver unique retail experiences that could set them apart and due to these changes, client is witnessing the sales growth. These creating the base for trend setting ….
            </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >

            </h6>
            <div
              className="text fsz-14 color-666 mb-90 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >

            </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >

            </h6>
            <div
              className="text fsz-14 color-666 mb-90 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >

            </div>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Architecture </a>
                    <a href="#"> Building </a>
                    <a href="#"> Wooden </a>
                    <a href="#"> Inspiration </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/team/team3.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Alexis Mac Alister</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      content editor
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      This a short description for introduce about the author,
                      you can write something in here such as personal
                      biography, favourite quote or anything that you want
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Related Posts </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="#"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b3.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            architecture, guide
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 22, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                            How to handle the day light in <br /> Vray for best
                            reality
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="#"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b4.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                            Sustainable Design Award 2024
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
