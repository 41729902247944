import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="#"> Home </a>
                <span className="icon">/</span>
                <a href="#"> News </a>
                <span className="icon">/</span>
                <a href="#">The Future of Salons: Reimagining the Salon Experience Centre</a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s">The salon industry has come a long way since the single-room setups of the 1990s. What was once a simple haircut service has 
                  <span className="color-orange1">transformed into a multifaceted industry </span> within just two decades, evolving into a revolutionary business that blends beauty, wellness, and lifestyle under one roof.
                </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <span className="lg-letter"> T </span>oday, salons face a choice: treat their space as a traditional business or embrace the idea of a full-fledged hospitality experience. Those who view the salon as a hospitality service are seeing incredible opportunities for growth, with a vast, eager customer base ready to embrace a more holistic approach to self-care.

              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >

              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                So, what could the salon of the future look like? Here's a glimpse into the "Experience Centre" approach that may soon define high-end salons:
                <a href="#" className="text-info text-decoration-underline">

                </a>
                
              </div>
            </div>
          </div>
        </div>
        {/* <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                 
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                 
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {/* <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >

            </h6> */}
            {/* <div
              className="text fsz-14 color-666 mb-40 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              
            </div> */}
           <ul
              className="list-styled color-666 fsz-14 lh-5 mb-40 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <li>
              1.	Waiting Lounge Café – Turning the waiting area into a relaxing café space.
              </li>
              <li>
              2.	Kids’ Studio & Play Area – A dedicated space for children, offering activities that keep them engaged.
              </li>
              <li>
              3.	Product Display for Family Essentials – Shelves featuring tailored family products, from toiletries to wellness essentials.
              </li>
              <li>4.	Natural Spa Services – Offering spa treatments infused with natural elements.</li>
              <li>5.	Oxygen Chambers – Innovative wellness spaces providing rejuvenating oxygen therapies.</li>
              <li>6.	VR Pedicure Stations – Combining pedicure services with virtual reality experiences.</li>
              <li>7.	Bridal Studio & Accessories – A one-stop studio for brides, with accessories and styling services.</li>
              <li>8.	Office Accessories – Curated selections catering to professional needs.</li>
              <li>9.	Personal Stylist & Digital Advisors – Personalized styling and digital consultations for beauty and wellness.</li>
              <li>10.	Mutual Fund & Financial Advisors – Catering to the modern customer’s lifestyle needs.</li>
            </ul>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
            </h6>
            <div
              className="text fsz-14 color-666 mb-90 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              Creating these spaces comes with its own set of challenges. As real estate prices surge, allocating resources and designing financially viable, multipurpose areas is becoming increasingly complex. Yet, the demand for a “personal wellness space” that provides an all-encompassing experience continues to grow.
                          </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >

            </h6>
            <div
              className="text fsz-14 color-666 mb-90 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             At DB Architect, our R&D team is actively exploring the future of salon design, seeking ways to create highly efficient, technology-integrated spaces that enhance customer experience. From booking systems and brand partnerships to digital advisors, we're preparing a design that brings a futuristic wellness space into reach—even in non-metro areas.
            </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Key Challenges in Future Salon Design:
            </h6>
            <div
              className="text fsz-14 color-666 mb-90 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
               <ul
              className="list-styled color-666 fsz-14 lh-5 mb-40 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <li>1	Real estate costs</li>
              <li>2	Financial feasibility of a multifaceted wellness space</li>
              <li>3	Optimal space utilization and efficient design</li>
            </ul>
            </div>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Architecture </a>
                    <a href="#"> Building </a>
                    <a href="#"> Wooden </a>
                    <a href="#"> Inspiration </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/team/team3.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Alexis Mac Alister</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      content editor
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      This a short description for introduce about the author,
                      you can write something in here such as personal
                      biography, favourite quote or anything that you want
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Related Posts </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="#"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b3.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            architecture, guide
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 22, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                            How to handle the day light in <br /> Vray for best
                            reality
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="#"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b4.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                            Sustainable Design Award 2024
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
