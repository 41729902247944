import React from 'react';

function Services() {
  return (
    <section className="tc-services-style1">
      <div className="content section-padding section-padding-x">
        <div className="container">
          <div className="title mb-80 text-center">
            <p className="color-666 text-uppercase wow"> our services </p>
          </div>
          <div className="services">
            <div className="row">
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20"> Architecture </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/services/DB-architekt-homes-368-chandigarh-bedroom1-photo1.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">

                    Innovative, sustainable architecture transforms spaces by blending functionality with creative design, enhancing environments through modern, eco-friendly building solutions. Experience thoughtful architectural excellence.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    {' '}
                    Interior{' '}
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/services/DB-architekt-corporate office interior-lobby view.JPG"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">

                    Interior design merges aesthetics with functionality, creating harmonious spaces that reflect personality, maximize comfort, and elevate everyday living experiences.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20"> Design </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/services/OFFICE-INTERIOR-office interior-MD-room-waiting area-view.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Design shapes ideas into reality, blending creativity, innovation, and purpose to craft visually compelling and functional solutions for diverse needs.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Design & Build
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/services/DB-ARCHITEKT-Lotus Valley International School-noida-interior-corridor-phase2.JPG"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">

                    Design and build integrate creative vision with construction expertise, streamlining the process to deliver cohesive, efficient, and custom-built environments from concept to completion.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Project Management
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/services/01-s-DB-architekt-liquorland-oneawadhcenter-lucknow.JPG"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">

                    Construction brings architectural designs to life, transforming blueprints into physical structures through skilled craftsmanship, precision, and sustainable building practices for lasting durability.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              {/* <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Residential
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/services/n_DB-R22-RETAILS_tlw-wine-vault-mg road-gurgaon-view-1 (1).jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Project Analysis, Bid Documentation, Construction Supervisor
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div> */}
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Architectural Signage
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/services/DB-2512-BYOB-08.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">

                    Architectural signage combines form and function, enhancing spaces with visually appealing, purposeful signs that guide, inform, and complement the overall design aesthetic.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Furniture Design
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/services/DB-ARCHITEKT-appolo clinic-golf course road-gurgaon-md room lounge.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">

                    Furniture blends functionality with design, creating pieces that enhance comfort, style, and the overall atmosphere of a space, making it both practical and visually appealing.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                   Lighting Design
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/services/DB-architekt_INDOOR Cricket Area .jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    
Lighting design shapes ambiance and functionality by strategically using light to enhance spaces, emphasizing mood, aesthetics, and usability while balancing natural and artificial sources.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a
              href="/innerpages/contact"
              className="butn rounded-pill mt-80 hover-bg-black bg-orange1 text-white"
            >
              <span>
                Let's Talk
                <i className="small ms-1 ti-arrow-top-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      {/* <div className="ser-img">
        <img src="/innerpages/assets/img/services/DB-ARCHITEKT-appolo clinic-golf course road-gurgaon-md room lounge.jpg" alt="" />
      </div> */}
    </section>
  );
}

export default Services;
