import React from 'react';

function Services() {
  return (
    <section className="tc-services-style1">
      <div className="content section-padding section-padding-x">
        <div className="container">
          <div className="title mb-80 text-center">
            <p className="color-666 text-uppercase wow"> our service sector </p>
          </div>
          <div className="services">
            <div className="row">
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20"> Residence </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/sector/DB-architekt-homes-sector.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  The home sector focuses on designing, building, and furnishing residential spaces that prioritize comfort, functionality, and personalized aesthetics, reflecting individual lifestyles and needs.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    {' '}
                    Housing{' '}
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/sector/DB-architekt-housing-Lotus Zing-sector.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  The housing sector involves the planning, construction, and development of residential properties, addressing affordability, sustainability, and urbanization to meet diverse living needs.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20"> Farm House </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/sector/DB-architekt-architecture-farm house-sector.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                 
The farmhouse sector emphasizes rustic architecture and sustainable practices, blending modern comforts with traditional designs to create serene, functional rural living spaces.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Hospitality
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/sector/DB-ARCHITEKT-Greenopolis-Club-sector.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                   

                  The hospitality sector focuses on providing exceptional experiences through innovative design and services in hotels, restaurants, and leisure facilities, enhancing guest satisfaction and comfort.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Institutional
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/sector/DB ARCHITEKT-HOTEL MANAGEMENT-CLASSROOM-sector.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  
                  The institutional sector involves designing and constructing facilities such as schools, hospitals, and government buildings, prioritizing functionality, safety, and community needs in every project.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              {/* <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Residential
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/services/n_DB-R22-RETAILS_tlw-wine-vault-mg road-gurgaon-view-1 (1).jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Project Analysis, Bid Documentation, Construction Supervisor
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div> */}
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                  Corporate Interior
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/sector/DB-architekt-corporate office-sector.JPG"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  The office sector emphasizes creating functional, collaborative spaces that enhance productivity, foster innovation, and reflect company culture through thoughtful design and modern amenities.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                  Retails and F & B
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/sector/DB-architekt-tlw-sector.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  The retail sector focuses on designing engaging spaces that enhance customer experience, promote brand identity, and drive sales through innovative layouts and visual merchandising strategies.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                  Industrial Design
                  </h5>
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/services/DB-Architekt-industrial-serctor.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  The industrial sector involves designing and constructing functional facilities for manufacturing, logistics, and warehousing, prioritizing efficiency, safety, and sustainability in operations and workflow.
                  </div>
                  {/* <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a
              href="#"
              className="butn rounded-pill mt-80 hover-bg-black bg-orange1 text-white"
            >
              <span>
                Let's Talk
                <i className="small ms-1 ti-arrow-top-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      {/* <div className="ser-img">
        <img src="/innerpages/assets/img/services/DB-ARCHITEKT-appolo clinic-golf course road-gurgaon-md room lounge.jpg" alt="" />
      </div> */}
    </section>
  );
}

export default Services;
