import React from 'react';

function Content() {
  return (
    <section className="tc-post-content-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info">
              <div
                className="breadcromb wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <a href="#"> Home </a>
                <span className="icon">/</span>
                <a href="#"> News </a>
                <span className="icon">/</span>
                <a href="#"> The role of Architectural Interns - Key Responsibilities and Skills Gained </a>
              </div>
              <h6
                className="fsz-24 fw-600 mb-60 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Architect Internship may be part of architectural course but while doing internship, one should take maximum advantages of exploring 
                <span className="color-orange1">new leering & first-hand experience</span> the practical implementing of ideas.
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <span className="lg-letter"> So </span>, what are the roles to be explored in internships? Let’s explore this article.
                <br/>Be part of design and development

Not only collage, in real practice also building design begins with concept, to craft dream in to creativity. In this process helping the project team ideas translating ideas in to detailed drawing – 3d models – shaping the sketches. One can take interest and create the new concept, take first hand guild from main project principal. 

              </div>
              <h6
                className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
               Study and Analysis
              </h6>
              <div
                className="text fsz-14 color-666 mb-70 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Intern also involved in study and analysis of design principles, local building material, trending global concept for particular project, new sustainability available option, interlinking sustainable and aesthetics. Also you will get chances for exploring design strategies, services & structural incorporation.
                <a href="#" className="text-info text-decoration-underline">
                Presentation:
                </a>
                Even in AI world, presentation make big difference. As a student one should explore – learn & create multiple presentation technique and skills. Even your option not selected; don’t worry principals help you to shape your dreams.
              </div>
            </div>
          </div>
        </div>
        {/* <div className="imgs mb-40">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.2s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b14.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                 
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="item text-center mb-50 wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <div className="img th-450 radius-6 overflow-hidden">
                  <img
                    src="/innerpages/assets/img/blog/b15.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
                <p className="fsz-14 color-666 fst-italic mt-30">
                 
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Rendering & model 
            </h6>
            <div
              className="text fsz-14 color-666 mb-40 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              Conceptual sketches will have to take shape to present our dream to client in the mode of rendering or scale model of outcome. 
            </div>
            {/* <ul
              className="list-styled color-666 fsz-14 lh-5 mb-40 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <li>
                Alternatively, brighten up the space with strategically placed
                lighting fixtures
              </li>
              <li>
                A well-placed mirror can create the illusion of a larger
                bathroom
              </li>
              <li>
                Consider using a hanging organizer on the back of your bathroom
                door to store toiletries or cleaning supplies.
              </li>
            </ul> */}
              <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Documentation:
            </h6>
            <div
              className="text fsz-14 color-666 mb-90 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Your main learning start from documentation work. Creating master pieces does not stop at designing alone. Various stages of documentation work is main part to shape the creativity like project documentation, construction detailing, technical drawing, 3d detailing, etc. creating documentation is not end of work, main work start with co-ordination of various stack holder like service consultant, structural consultant, speciality consultant and energy consultant etc. these stages will give imence details of how project process…..
            </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             SITE VISITS
            </h6>
            <div
              className="text fsz-14 color-666 mb-90 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Real drama performance start from here. From site visits will create opportunity how blueprints transforming in to erected structure. Site discussion, construction issues, documenting process all will assisting in rectifying /aligning design in right tract. 

Work pressure 
Not only collage having submission date, real life also having deadlines for all work outputs. 

            </div>
            <h6
              className="fsz-24 fw-600 mb-30 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Professional journey 
            </h6>
            <div
              className="text fsz-14 color-666 mb-90 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Last but not least, internship will provide glimpse of professional world of architect. Above are few important job profile of architect. You may be not involved in all may be few. May be at the end of your week, you can ask & explore the each area. 
Internship- will open up your ideas, vision and implementation of your creation. These will really help in your final year thesis.  
            </div>
            <div
              className="share-content wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="tags">
                    <a href="#"> Architecture </a>
                    <a href="#"> Building </a>
                    <a href="#"> Wooden </a>
                    <a href="#"> Inspiration </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="share mt-4 mt-lg-0 justify-content-lg-end">
                    <span className="txt me-3"> Share on </span>
                    <div className="links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="editor wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="row">
                <div className="col-lg-2">
                  <div className="img">
                    <img
                      src="/innerpages/assets/img/team/team3.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="cont mt-4 mt-lg-0">
                    <h6 className="fsz-18 fw-bold mb-2">Alexis Mac Alister</h6>
                    <small className="fsz-12 text-uppercase color-666">
                      content editor
                    </small>
                    <div className="text fsz-14 fw-500 mt-20">
                      This a short description for introduce about the author,
                      you can write something in here such as personal
                      biography, favourite quote or anything that you want
                    </div>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-x-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-blogger"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-discord"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="related-posts">
              <div className="title mb-70">
                <div
                  className="row align-items-center wow fadeInUp slow"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-8">
                    <h3 className="fsz-45 text-capitalize"> Related Posts </h3>
                  </div>
                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="arrows me-lg-0 ms-lg-auto">
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="related-slider wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="#"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b3.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            architecture, guide
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 22, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                            How to handle the day light in <br /> Vray for best
                            reality
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="post-card">
                      <a
                        href="#"
                        className="img th-280 radius-7 overflow-hidden d-block"
                      >
                        <img
                          src="/innerpages/assets/img/blog/b4.jpg"
                          alt=""
                          className="img-cover"
                        />
                      </a>
                      <div className="info pt-30">
                        <div className="tags color-666 text-uppercase fsz-12">
                          <a href="#" className="color-orange1">
                            inspiration, interior
                          </a>
                          <span className="circle icon-3 bg-666 rounded-circle mx-3"></span>
                          <a href="#"> march 7, 2024 </a>
                        </div>
                        <h3 className="title mt-15">
                          <a href="#" className="hover-orange1 fsz-24">
                            Sustainable Design Award 2024
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
